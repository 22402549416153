// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;


// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

:root {
    --primaryColor: #0a1857;
    --secondaryColor: #c9341e;
    --greenColor:#73b43c;
    // --primaryColor: #081632;
    // --secondaryColor: #c9341e;
    
}

// If you want to add custom CSS you can put it here.
@import "custom";
